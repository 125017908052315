.article {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;

    @media (--screen-filter) {
        flex-direction: row;
    }

    hr {
        border: 0;
        border-bottom: .1rem solid var(--color-tertiary);
        margin-bottom: 4rem;

        &.-max {
            display: none;

            @media (--screen-filter) {
                display: block;
            }
        }
    }

    h2, h3 {
        font-family: var(--font-chivo);
        font-weight: var(--font-weight-bold);
        font-size: 2.2rem;
        margin-bottom: 1rem;

        + p {
            margin-top: 0;
        }
    }

    h2 {
        font-size: 2.6rem;
    }

    p + h2, p + h3,
    ul + h2, ul + h3 {
        margin-top: 4rem;
    }
}

.article__content {
    width: 100%;
    color: var(--color-gray);
    font-family: var(--font-pt-serif);
    font-size: 1.7rem;
    line-height: 1.6;
    max-width: 72rem;

    &.-center {
        text-align: center;
        margin: 0 auto 4rem auto;
    }

    &.-padded-bottom {
        padding-bottom: 4rem;
    }

    .alert-message__close {
        display: none;
    }
}

.article__info {
    margin: 0 0 3rem;
    width: 100%;

    @media (--screen-filter) {
        margin: 0 0 0 auto;
        padding-left: 3rem;
        max-width: 44rem;
    }

    @media (--screen-max) {
        max-width: 60rem;
    }

    img {
        width: 100%;
    }
}

.article__info__content {
    padding: 1.5rem 1.5rem 4rem;
    background-color: var(--color-quaternary);
    text-align: left;
    margin: 0 -1.5rem;

    @media (--screen-filter) {
        margin: 0;
        padding: 2.5rem 2.5rem 7rem;
    }

    &.-bottom {
        padding-bottom: 1.5rem;

        @media (--screen-filter) {
            position: sticky;
            top: 0;
            z-index: 1;
            border-bottom: .2rem solid var(--color-primary-60);
            padding-bottom: 2.5rem;
        }
    }

    &.-max {
        display: none;

        @media (--screen-filter) {
            display: block;
        }
    }

    &.-small {
        display: block;

        @media (--screen-filter) {
            display: none;
        }
    }

    :last-child {
        margin-bottom: 0;
    }
}

.article__title {
    color: var(--color-black);
    line-height: 1.05;
    font-size: 3rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    display: block;
    margin: 0 0 1.5rem;

    @media (--screen-medium-large) {
        margin: 0 0 3rem;
    }

    @media (--screen-filter) {
        font-size: 6.2rem;
    }

    &.-center {
        text-align: center;
    }

    &.-extra-margin {
        @media (--screen-medium-large) {
            margin-bottom: 4.5rem;
        }
    }

    &.-rooms {
        @media (--screen-medium-large) {
            margin-bottom: 2rem;
        }
    }

    &.-events {
        margin-bottom: .5rem;
    }

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-black);
        font-style: normal;
    }
}

.article__subtitle, .article .article__subtitle {
    color: var(--color-primary);
    font-size: 2rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-regular);
    margin: 0 0 1rem;

    @media (--screen-filter) {
        font-size: 2.8rem;
    }

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-regular);
        font-style: normal;
    }

    a {
        font-size: 1.7rem;
    }
}

.article__subsubtitle {
    color: var(--color-dark-gray);
    font-size: 2rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-regular);
    margin: 0 0 1.5rem;
}

.article__date {
    background-color: var(--color-secondary);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.8rem;
    color: var(--color-white);
    display: inline-block;
    text-transform: uppercase;
    padding: 1rem;
    transition: all .3s ease-in-out;
    position: relative;
    margin-bottom: 4rem;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -.6rem;
        display: inline-block;
        width: 1.2rem;
        height: 100%;
        transform: skew(15deg);
        background-color: var(--color-secondary);
        z-index: 0;
    }

    &.-small {
        margin-bottom: 1rem;
    }
}

.article__meta {
    display: block;
    margin: 0 0 4rem;
    color: var(--color-primary);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 2.8rem;
    position: relative;
    top: -1.7rem;

    sup {
        font-size: 50%;
    }
}

